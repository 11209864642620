// 关联门店
<template>
  <div class="item">
    <TitleHeader msg="关联门店"></TitleHeader>
    <el-row class="MyAssociatedAccount">
      <div class="LineBox">
          <span class="LineTit">会员名称：</span>
           <el-input
                class="MemberNameInps"
                v-model="MemberName"
                placeholder="请输入要关联的会员名称"
                @input="selectFun"
                clearable
                @clear="clearFun"
              ></el-input>
              <p class="msgArt"> <i class="el-icon-warning"></i> 仅支持关联复审通过及资质过期的会员</p>
            <div class="searchBox" v-show="SearchFlag" >
                <div class="line"></div>
                <div class="nav_box">
                     <ul>
                        <li  v-for="(item,index) in list" :key="index"  @click="CliFun(item)">
                                {{item.enterpriseName}}
                        </li>
                    </ul>
                </div>
               
            </div>
      </div>
      <div class="LineBox">
          <span class="LineTit">登录账号：</span>
          <div class="AccountBox"  @click="AccountFun">{{AccountMsg}}</div>
      </div>
      <div class="LineBox">
          <span class="LineTit">短信验证码：</span>
          <el-input
                class="vscodeInps"
                ref="vscodeInp"
                v-model="vscode"
                placeholder="请输入验证码"
                @change="validateFun"
              ></el-input>
               <el-button
               v-show="phone!=''"
              class="vscodeBtn"
              :class="{ disabled: !this.canClick }"
              @click="countDown"
              >{{ content }}</el-button
            >
      </div>
     <div class="LineBox">
            <el-button     :class=" !vscode? 'SureBtn disabled' : 'SureBtn'"   @click="submitForm"
              >完成</el-button
            >
      </div>
    </el-row>
  </div>
</template>
<style lang="less" scoped>
@import "~style/index.less";
.item {
  .MyAssociatedAccount {
    padding: 30px;
    position: relative;
    .LineBox{
        margin-bottom:24px;
        .LineTit{
                width: 100px;
                text-align: right;
                vertical-align: middle;
                float: left;
                font-size: 14px;
                color: #606266;
                line-height: 40px;
                padding: 0 12px 0 0;
                box-sizing: border-box;
        }
        .MemberNameInps {
            position: relative;
            display: inline-block;
            width:300px;
            font-size: 14px;
        }
        .searchBox{
            width: 298px;
            height:auto;
            position: absolute;
            left:130px;
            top:66px;
            border:1px solid #409eff;
            z-index: 9;
            background: #fff;
            border-top:none;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            .line{
                margin-left:15px;
                width: 268px;
                height: 1px;
                background: #ccc;
            }
            ::-webkit-scrollbar  
                {  
                    width: 5px;  
                }
                
                /*定义滚动条轨道 内阴影+圆角*/  
                ::-webkit-scrollbar-track  
                {  
                    border-radius: 10px;  
                    background-color: rgba(126, 126, 126, 0.1); 
                } 
                
                /*定义滑块 内阴影+圆角*/  
                ::-webkit-scrollbar-thumb  
                {  
                    border-radius: 10px;  
                    -webkit-box-shadow: inset 0 0 6px rgba(126, 126, 126, 0.1);  
                    background-color: rgba(126, 126, 126, 0.1);
                } 
            .nav_box{
                overflow-y: scroll;
                overflow-x: hidden;
                box-sizing: border-box;
                max-height: 260px;
                padding:0 15px;
                li{
                    line-height:36px;
                    font-size: 13px;
                    color: #666;
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                     cursor: pointer;
                      &:hover{
                        color: #000;
                    }
                }
            }
        }
        .AccountBox{
            position: relative;
            display: inline-block;
            width:298px;
            font-size: 14px;
            height:38px;
            background: linear-gradient(#FAFAFA, #EBEBEB); 
            color: #666;
            border:1px solid #ccc;
            text-align: center;
            line-height: 40px;
            &:hover{
                 cursor: pointer;
            }
        }
         .vscodeInps {
            position: relative;
            display: inline-block;
            width:300px;
            font-size: 14px;
        }
        .vscodeBtn {
            position: relative;
            font-size: 14px;
            display: inline-block;
            width:120px;
            background: #fff;
            color: #597EF7;
            border: none;
            cursor: pointer;
        }
    }
    .SureBtn {
        background: #ff3333;
        color: #fff;
        border: 1px solid #ff3333;
        width: 136px;
        height: 36px;
        margin-left: 100px;
        text-align: center;
        line-height: 36px;
        font-size: 15px;
        font-weight: bold;
        border-radius: 0;
        padding: 0;
      }
      .SureBtn.disabled {
          background-color: #ddd;
          border-color: #ddd;
          color: #ed4345;
          cursor: not-allowed; // 鼠标变化
        }
    //   ////////////////
    .num {
      font-weight: 600;
      color: #f04134;
      padding-right: 30px;
    }
    .wraper {
      display: flex;
      justify-content: left;
      align-items: center;
      .el-input {
        position: relative;
        font-size: 14px;
        display: inline-block;
        width: 70%;
      }
      .vscodeInps {
        position: relative;
        font-size: 14px;
        display: inline-block;
        width:40%;
      }
      .vscodeInp {
        position: relative;
        font-size: 14px;
        display: inline-block;
        width:70%;
      }
      
     
      
    }
    /deep/.el-form-item__error {
      color: #f56c6c;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      position: absolute;
      top: 10px;
      left: calc(70% + 10px);
    }
  }
  .msgArt{
        font-size: 14px;
    color: #ccc;
    text-indent: 50px;
    margin-top: 10px;
  }
}
</style>
<script>
import {GetLikeByName,relevanceStoreSms,relevanceStore} from "api/member.js"
const TitleHeader = () => import("components/common/TitleHeader.vue");
export default {
  name: "MyAssociatedAccount",
  data() {
   
    return {
        list:[],
        MemberName:'',//搜索值
        SearchFlag:false,
        vscode:'',//验证码
        vscodeMsg: "获取验证码",
       // 点击倒计时
      content: "发送验证码", // 按钮里显示的内容
      totalTime: 60, //记录具体倒计时时间
      canClick: false, //添加canClick
      AccountMsg:'账号',
      phone: '',
      memberStoreId:''
    };
  },
  methods: {
      selectFun(){
          let datas={
              current:1,
              size:30,
              enterpriseName:this.MemberName
          }
          GetLikeByName(datas).then(
              res=>{
                  this.list=res.data.data;
                  if(this.list.length>0){
                       this.SearchFlag=true;
                  }else{
                      this.SearchFlag=false;
                  }
              }
          )
      },
      CliFun(item){
          this.SearchFlag=false;
           this.MemberName=item.enterpriseName;
           this.phone=item.userPhone;
           this.AccountMsg=this.phone;
           this.memberStoreId=item.id;
           this.canClick=true
      },
    clearFun(){
        this.phone=''
        this.AccountMsg='会员名称',
        this.memberStoreId='';
           this.canClick=false
    },
    AccountFun(){
        if(this.phone==''){
            this.$message({
                message: '请先搜索会员',
                type: 'warning'
            });
        }
    },
    // 倒计时
    countDown() {
      if (!this.canClick) return; //改动的是这两行代码
      this.canClick = false;
      this.content = this.totalTime + "s后重新发送";
      //发送验证码
      let datas={
          memberStoreId:this.memberStoreId
      }
      relevanceStoreSms(datas).then((data)=>{
        const h = this.$createElement;
        this.$notify({
          title: '成功',
          message:h('i', { style: 'color: teal'},data.data.msg),
          type: 'success'
        });
      })

      let clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.content = "重新发送验证码";
          this.totalTime = 60;
          this.canClick = true; //这里重新开启
        }
      }, 1000);
    },
    validateFun(){
      //表单验证
         if (!Number.isInteger(Number(this.vscode))) {
          this.$message({
                message: '请输入数字值',
                type: 'warning'
            });
          return false
        } else {
          if (this.vscode.length!=6) {
            this.$message({
                  message: '必须为六位数字',
                  type: 'warning'
              });
            return false
          } else {
             return false
          }
        }
    },
    //   点击提交 验证
    submitForm() {
         let  Updatedata={
            code:this.vscode,
            memberStoreId:this.memberStoreId,
          }
      relevanceStore(Updatedata).then(data=>{
            if(data.data.code==400){
                 
              let msg=data.data.msg;
              if(msg=='验证码错误，请重新输入'){
                  this.vscode=''
                  this.$refs.vscodeInp.focus();
              }
                this.$message({
                message: data.data.msg,
                type: 'warning'
              });
            }else{
            this.$message({
              message: '恭喜您，您已关联成功',
              type: 'success'
            });
            this.$router.push({ name: "MyInvoice" });
          }
      })
     
    },
   

  },
  created() {
   
  },
  components: {
    TitleHeader,
  },
};
</script>
